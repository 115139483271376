<template>
  <v-layout column>
    <div class="align-center text-center mt-2">
      <v-card elevation="6" max-width="600" style="margin: auto">
        <v-toolbar flat dense class="blue darken-3" dark>
          <v-toolbar-title>Company Register Page</v-toolbar-title>

          <v-spacer></v-spacer>

          <v-btn
            class="grey lighten-3 black--text mr-4"
            v-if="!$store.state.isUserLoggedIn"
            @click="navigateTo({ name: 'register' })"
          >
            <v-icon>person_outline</v-icon>
            Individual
          </v-btn>

          <v-btn
            class="grey lighten-3 black--text"
            v-if="!$store.state.isUserLoggedIn"
            @click="navigateTo({ name: 'registerCompany' })"
          >
            <v-icon class="mr-1">work_outline</v-icon>
            Company
          </v-btn>
        </v-toolbar>

        <!--class that defines paddings(left, right, top && bottom-->
        <div class="pl-4 pr-4 pt-2 pb-4">
          <form name="register-form" autocomplete="off">
            <v-text-field
              v-model="username"
              label="Username"
              filled
            ></v-text-field>
            <v-text-field v-model="email" label="Email" filled></v-text-field>
            <v-text-field
              v-model="password"
              label="Password"
              :append-icon="value ? 'visibility' : 'visibility_off'"
              @click:append="() => (value = !value)"
              :type="value ? 'password' : 'text'"
              filled
            ></v-text-field>
            <v-text-field
              v-model="companyId"
              label="Company ID"
              filled
            ></v-text-field>
          </form>
          <div v-html="error" />

          <v-btn elevation="2" @click="register">
            <v-icon>app_registration</v-icon>
            Register
          </v-btn>
        </div>
      </v-card>
    </div>
  </v-layout>
</template>

<script>
import AuthenticationService from "@/services/AuthenticationService";
export default {
  data() {
    return {
      username: "",
      email: "",
      password: "",
      companyId: "",
      value: String,
      error: null,
    };
  },
  methods: {
    async register() {
      try {
        const response = await AuthenticationService.registerCompany({
          username: this.username,
          email: this.email,
          password: this.password,
          companyId: this.companyId,
        });
        console.log('response: ', response)

        this.$router.push({
          name: "lobby",
          query: { redirect: "/lobby" },
        });
      } catch (error) {
        this.error = error.response.data.error;
      }
    },
    navigateTo(route) {
      this.$router.push(route);
    },
  },
};
</script>

<style scoped>
.error {
  color: red;
}
</style>
